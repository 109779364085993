import React, {useEffect, useRef, useState} from "react";
import S3Image from "./S3Image";

const ImageFileInputWithPreview = ({id, onChange, placeholder, image}) => {
    const [imagePlaceholder, setImagePlaceholder] = useState(placeholder);

    const input = useRef();

    function selectFile() {
        input.current.click();
    }

    function handleChange(event) {
        const [file,] = event.target.files || [];
        if (file) {
            if (onChange) {
                onChange(file);
            }
            URL.revokeObjectURL(imagePlaceholder);
            setImagePlaceholder(URL.createObjectURL(file));
        }
    }

    useEffect(() => {
        return () => URL.revokeObjectURL(imagePlaceholder)
    }, [imagePlaceholder])

    return (
        <div>
            <input id={id} accept="image/*" capture ref={input} type="file"
                   onChange={handleChange} className="hide"/>
            {image && Object.keys(image).includes('key') ?
                <S3Image image={image} alt={image.key} onClick={selectFile}/> :
                imagePlaceholder ?
                    <img className="img-container" src={imagePlaceholder} alt="Profile"
                         onClick={selectFile}/> : ''}
        </div>
    );
}

export default ImageFileInputWithPreview;