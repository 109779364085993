import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {createTagTile, updateTile} from "../../graphql/mutations";
import {listTagsMinimal} from "./graphql";
import {Navigate, useNavigate} from "react-router-dom";
import {compareString} from "../../Utility";
import {deleteTagTile} from "./Tile";
import ImageInput from "../../util/ImageInput";
import imagePlaceholder from "../../util/imgPlaceholder.png";
import {v4 as uuid} from "uuid";

const EditTile = ({tile, onUpdate}) => {
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        API.graphql(graphqlOperation(listTagsMinimal))
            .then(result => setTags(result.data.listTags.items))
            .catch(error => console.log(error));
    }, []);

    const formik = useFormik({
        initialValues: {
            image: tile?.icon,
            name: tile?.name,
            tags: tile?.tags.items.map(tt => tt.tagID)
        }, validate: ({image, name, tags}) => {
            const errors = {};
            if (!image) {
                errors.image = 'Required';
            }
            if (!name) {
                errors.name = 'Required';
            }
            if (tags.length === 0) {
                errors.tags = 'Select at least one tag';
            }
            return errors;
        }, onSubmit: async ({image, name, tags}) => {
            try {
                const input = {
                    id: tile.id,
                    name
                };
                if (!Object.keys(image).includes('key')) {
                    const deleteResult = await Storage.remove(tile.icon.key, {
                        level: 'protected'
                    });

                    console.log('delete image', deleteResult);

                    const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(image.name);
                    const icon = 'tile/' + [uuid(), extension].filter(x => !!x).join('.');
                    const savedIcon = await Storage.put(icon, image, {
                        level: 'protected'
                    });
                    console.log('save image', savedIcon);
                    savedIcon.level = 'protected';
                    const identity = await Auth.currentCredentials();
                    savedIcon.identityId = identity.identityId;
                    input.icon = savedIcon;
                }

                const result = await API.graphql(graphqlOperation(updateTile, {
                    input: input
                }));

                const tileId = result.data.updateTile.id;
                const removedTags = tile.tags.items.filter(tt => !tags.includes(tt.tagID));
                const addedTags = tags.filter(id => !tile.tags.items.map(tt => tt.tagID).includes(id));
                await Promise.all([
                    ...removedTags.map(tt => API.graphql(
                        graphqlOperation(deleteTagTile, {
                            input: {
                                id: tt.id
                            }
                        })
                    )),
                        ...addedTags.map(tagID => API.graphql(
                            graphqlOperation(createTagTile, {
                                input: {
                                    tagID,
                                    tileID: tileId
                                }
                            })
                        ))
                    ]
                );
                onUpdate();
                navigate(-1);
            } catch (error) {
                console.log(error)
                formik.setStatus({error: error.errors ? error.errors.map(e => e.message) : error.message})
            }
        }
    });

    if (!tile) {
        return <Navigate to={'/admin/tiles'}/>
    }

    return <div className="popup">
        <form className="popup-inner" onSubmit={formik.handleSubmit}>
            {formik.status && formik.status.error && <p className='form-error'>{formik.status.error}</p>}
            <div className="tile-image">
                <div className="img-container tile-icon">
                    <ImageInput id="image"
                                name="image"
                                placeholder={imagePlaceholder}
                                image={formik.values.image}
                                onChange={(file) => formik.setFieldValue('image', file)}/>
                    {formik.errors.image && <span className="form-error">{formik.errors.image}</span>}

                    {/*<S3Image image={tile.icon} alt={tile.name}/>*/}
                </div>
            </div>
            <div>
                <label htmlFor="name">Name</label>
                <input id="name"
                       name="name"
                       type="text"
                       placeholder="Name"
                       onChange={formik.handleChange}
                       value={formik.values.name}
                />
                {formik.errors.name && <span className="form-error">{formik.errors.name}</span>}
            </div>
            <div>
                <label>Tags</label>
                <select id="tags"
                        name="tags"
                        value={formik.values.tags}
                        onChange={formik.handleChange}
                        className="custom-select"
                        multiple>
                    {tags.sort((a, b) => compareString(a.name, b.name))
                        .map(tag => <option key={tag.id}
                                            value={tag.id}>{tag.name}</option>)}
                </select>
                {formik.errors.tags && <span className="form-error">{formik.errors.tags}</span>}
            </div>
            <div>
                <button disabled={formik.isSubmitting}
                        type="button"
                        className="btn-main btn-right"
                        onClick={() => navigate(-1)}>
                    Cancel
                </button>
                <button disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                        type="submit"
                        className="btn-main btn-left">
                    Update tile
                </button>
            </div>
        </form>
    </div>;
}

export default EditTile;