import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./stylesheet.css";
import PublicRootPage from "./public/PublicRootPage";
import {getSessionId, setSessionId as setGlobalSessionId} from "./Utility";
import ChangePassword from "./private/auth/ChangePassword";
import SessionOverview from "./private/session/SessionOverview";
import TagOverview from "./private/tag/TagOverview";
import TileOverview from "./private/tile/TileOverview";
import ResumeOverview from "./private/resume/ResumeOverview";
import PrivateRootPage from "./private/PrivateRootPage";
import Start from "./public/Start";
import ResumeBuilder from "./public/resume/ResumeBuilder";
import SessionSelector from "./public/util/SessionSelector";
import {API, Auth} from "aws-amplify";
import {getSession} from "./graphql/queries";

const App = () => {
    const [session, setSession] = useState(undefined);

    useEffect(() => {
        const sessionId = getSessionId();
        if (sessionId) {
            Auth.currentCredentials().then(credentials => API.graphql({
                    query: getSession,
                    variables: {
                        id: getSessionId(),
                    },
                    authMode: credentials.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                })
            )
                .then(result => setSession(result.data.getSession))
                .catch(console.error)
        }
    }, []);

    function loadSession(session) {
        setGlobalSessionId(session.id);
        setSession(session);
    }

    return (<BrowserRouter>
        <Routes>
            <Route path="/" element={<PublicRootPage sessionId={session?.id}/>}>
                <Route index element={<Start session={session}/>}/>
                <Route path="resume/*" element={<ResumeBuilder sessionId={session?.id} owner={session?.owner}/>}/>
                <Route path="session" element={<SessionSelector/>}/>
            </Route>
            <Route path="/admin" element={<PrivateRootPage/>}>
                <Route path="changepassword" element={<ChangePassword/>}/>
                <Route path="resumes/*" element={<ResumeOverview/>}/>
                <Route path="sessions/*" element={<SessionOverview openSession={loadSession}/>}/>
                <Route path="tiles/*" element={<TileOverview/>}/>
                <Route path="tags/*" element={<TagOverview/>}/>
            </Route>
        </Routes>
    </BrowserRouter>);
}

export default App;