import React, {useState} from "react";
import {useFormik} from "formik";
import {API, graphqlOperation} from "aws-amplify";
import {deleteTag, updateTag} from "../../graphql/mutations";
import {FaArrowDown, FaArrowUp, FaEdit, FaTrash} from "react-icons/fa";

const Tag = ({tag, onChange, isFirst, isLast, onRemove, up, down, moveEnabled, setMoveEnabled}) => {
    const [editing, setEditing] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: tag.name, description: tag.description
        }, validate: ({name, description}) => {
            const errors = {};

            if (!name) {
                errors.name = 'Required';
            }
            if (!description) {
                errors.description = 'Required';
            }

            return errors;
        }, onSubmit: async ({name, description}) => {
            API.graphql(graphqlOperation(updateTag, {
                input: {
                    id: tag.id, name, description
                }
            })).then(result => {
                onChange(result.data.updateTag);
                setMoveEnabled(true);
                setEditing(false);
            }).catch(error => formik.setStatus({error: error.message}));
        }
    });

    function edit() {
        setEditing(true);
        setMoveEnabled(false);
    }

    function remove() {
        API.graphql(graphqlOperation(deleteTag, {input: {id: tag.id}}))
            .then(() => onRemove(tag))
    }

    function cancel() {
        setEditing(false);
    }

    if (!editing) {
        return (<tr key={tag.id}>
            <td>{tag.name}</td>
            <td>{tag.description}</td>
            <td className="vcenter">
                <span>{tag.order}</span>
                <div className="tag-order-btn-container">
                    <button type="button" className="tag-order-up btn-icon table-icon"
                            onClick={up} disabled={isFirst || !moveEnabled}>
                        <FaArrowUp/>
                    </button>
                    <button type="button" className="tag-order-down btn-icon table-icon"
                            onClick={down} disabled={isLast || !moveEnabled}>
                        <FaArrowDown/>
                    </button>
                </div>
            </td>
            <td className="td-action">
                <button type="button" className="tag-edit btn-icon table-icon"
                        onClick={edit} title="Edit tag">
                    <FaEdit/>
                </button>
                <button type="button" className="tag-delete btn-icon table-icon"
                        onClick={remove} title="Delete tag">
                    <FaTrash/>
                </button>
            </td>
        </tr>)
    } else {
        return (<tr key={tag.id}>
            <td>
                <input type="text"
                       name="name"
                       onChange={formik.handleChange}
                       value={formik.values.name}/>
                {formik.errors.name && <span className="form-error">{formik.errors.name}</span>}
            </td>
            <td>
                <input type="text"
                       name="description"
                       onChange={formik.handleChange}
                       value={formik.values.description}/>
                {formik.errors.description && <span className="form-error">{formik.errors.description}</span>}
            </td>
            <td>{tag.order}</td>
            <td className="td-action">
                <button id="tag-save" disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                        type="submit" onClick={formik.submitForm}
                        className="btn-main btn-left">Save
                </button>
                <button id="tag-cancel" disabled={formik.isSubmitting} type="button"
                        className="btn-main btn-right"
                        onClick={cancel}>Cancel
                </button>
                {formik.status && formik.status.error && <p className='form-error'>{formik.status.error}</p>}
            </td>
        </tr>)
    }
}

export default Tag;