import Header from "./Header";
import {Outlet, useLocation} from "react-router-dom";
import React from "react";

const PublicRootPage = () => {
    const location = useLocation();

    return (
        <>
            <Header showAdminLink showBackLink={location.pathname.includes('resume')}/>
            <Outlet/>
        </>
    );
}

export default PublicRootPage;