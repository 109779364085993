const SessionSelector = () => {
    return (<div>Session selector placeholder</div>);
    // const [sessionId, setSessionId] = useState('')
    //
    // function submit() {
    //     if (!sessionId) return
    //     Util.setSessionId(sessionId);
    // }
    //
    // return (
    //     <div style={styles.container}>
    //         <h2>Amplify Resume</h2>
    //         <input
    //             onChange={event => setSessionId( event.target.value)}
    //             style={styles.input}
    //             value={sessionId}
    //             placeholder="Session identifier"
    //         />
    //         <button style={styles.button} onClick={submit}>Activate session</button>
    //     </div>
    // );
}

export default SessionSelector;