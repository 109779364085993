import React, {useEffect, useState} from "react";
import {listTags} from "../../graphql/queries";
import {updateTag} from "../../graphql/mutations";
import {API, graphqlOperation} from "aws-amplify";
import {Route, Routes, useNavigate} from "react-router-dom";
import CreateTag from "./CreateTag";
import Tag from "./Tag";

const TagOverview = () => {
    const [tags, setTags] = useState([]);
    const [moveEnabled, setMoveEnabled] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchTags();
    }, []);

    function fetchTags() {
        API.graphql(graphqlOperation(listTags, {}))
            .then(result => setTags(result.data.listTags.items))
            .catch(reason => console.log(reason))
    }

    function update(tag) {
        setTags(tags => {
            const index = tags.findIndex(s => s.id === tag.id)
            tags[index] = {...tags[index], ...tag};
            return [...tags];
        });
    }

    function deleteTag(tag) {
        setTags(tags => {
            return [...tags.filter(t => t.id !== tag.id)];
        });
    }

    function swap(i1, i2) {
        if (0 <= i1 <= tags.length && 0 <= i2 <= tags.length && i1 !== i2) {
            const first = tags[i1];
            const fO = first.order;
            const second = tags[i2];
            first.order = second.order;
            second.order = fO;
            const fP = API.graphql(graphqlOperation(updateTag, {input: {id: first.id, order: second.order}}));
            const sP = API.graphql(graphqlOperation(updateTag, {input: {id: second.id, order: first.order}}));
            Promise.all([fP, sP])
                .then(() => setTags(tags => {
                        tags[i1] = first;
                        tags[i2] = second;
                        return [...tags];
                    })
                )
                .catch(error => console.log(error));
        }
    }

    const sorted = tags.sort((a, b) => a.order - b.order)

    return (<main>
        <h2>Manage tags</h2>
        <button className="btn-main btn-addTag clearfix" onClick={() => {
            navigate('/admin/tags/create')
        }}> Add tag
        </button>
        <Routes>
            <Route path="create"
                   element={<CreateTag onCreate={fetchTags}
                                       order={sorted.length !== 0 ? sorted[sorted.length - 1].order + 1 : 0}/>}/>
        </Routes>
        <div className="clearfix">
            <table className="table table-overview" width="100%">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Order</th>
                    <th className="td-action">Action</th>
                </tr>
                </thead>
                <tbody>
                {sorted
                    .map((tag, i, arr) =>
                        <Tag key={tag.id}
                             tag={tag}
                             isFirst={i === 0}
                             isLast={i === arr.length - 1}
                             up={() => swap(i - 1, i)}
                             down={() => swap(i, i + 1)}
                             onChange={update}
                             onRemove={deleteTag}
                             moveEnabled={moveEnabled}
                             setMoveEnabled={setMoveEnabled}
                        />)}
                </tbody>
            </table>
        </div>
    </main>);
}

export default TagOverview;