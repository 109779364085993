import React, {useEffect, useState} from "react";
import {listSessions} from "../../graphql/queries";
import {updateSession} from "../../graphql/mutations";
import {API, graphqlOperation} from "aws-amplify";
import {compareString} from "../../Utility";
import {FaBookmark, FaEdit, FaLock} from "react-icons/fa";
import {useFormik} from "formik";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";
import CreateSession from "./CreateSession";

const SessionOverview = ({openSession}) => {
    const [sessions, setSessions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSessions();
    }, []);

    function fetchSessions() {
        API.graphql(graphqlOperation(listSessions))
            .then(result => setSessions(result.data.listSessions.items))
            .catch(reason => console.log(reason))
    }

    function updateSession(session) {
        setSessions(sessions => {
            const index = sessions.findIndex(s => s.id === session.id)
            sessions[index] = {...sessions[index], ...session};
            return [...sessions];
        });
    }

    return (<main>
        <h2>Manage sessions</h2>
        <button className="btn-main btn-addTag clearfix" onClick={() => {
            navigate('/admin/sessions/create')
        }}> Add session
        </button>
        <Routes>
            <Route path="create" element={<CreateSession onCreate={fetchSessions}/>}/>
        </Routes>
        <div className="clearfix">
            <table className="table table-overview" width="100%">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th className="td-action">Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    sessions.sort((a, b) => compareString(a.name, b.name))
                        .map(session =>
                            <Session key={session.id}
                                     session={session}
                                     openSession={openSession}
                                     onChange={updateSession}/>
                        )
                }
                </tbody>
            </table>
        </div>
    </main>);
}

const Session = ({session, onChange, openSession}) => {
    const [editing, setEditing] = useState(false);
    const {signOut} = useAuthenticator(context => [context.authStatus])
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: session.name,
            location: session.location,
            date: session.date
        },
        validate: ({name, location, date}) => {
            const errors = {};

            if (!name) {
                errors.name = 'Required';
            }
            if (!location) {
                errors.location = 'Required';
            }
            if (!date) {
                errors.date = 'Required'
            }

            return errors;
        },
        onSubmit: async ({name, location, date}) => {
            API.graphql(graphqlOperation(updateSession, {
                input: {
                    id: session.id,
                    name,
                    location,
                    date
                }
            })).then(result => {
                onChange(result.data.updateSession);
                setEditing(false);
            }).catch(error => formik.setStatus({error: error.message}));
        }
    });

    function open() {
        openSession(session);
        signOut();
        navigate("/")
    }

    function edit() {
        setEditing(true);
    }

    async function end() {
        API.graphql(graphqlOperation(updateSession, {
            input: {
                id: session.id,
                ended: true
            }
        })).then(result => {
            onChange(result.data.updateSession)
        })
            .catch(err => console.log(err));
    }

    function cancel() {
        setEditing(false);
    }

    if (!editing) {
        return (
            <tr key={session.id}>
                <td>{session.name}</td>
                <td>{session.location}</td>
                <td>{session.date}</td>
                <td>{session.ended ? "Done" : "Ongoing"}</td>
                <td className="td-action">
                    <button id="session-set" type="button" className="btn-icon table-icon"
                            onClick={open} title="Activate this session and log out">
                        <FaBookmark/>
                    </button>
                    <button id="session-edit" type="button" className="btn-icon table-icon"
                            onClick={edit} title="Edit session">
                        <FaEdit/>
                    </button>
                    <button id="session-end" type="button" className="btn-icon table-icon"
                            onClick={end} title="Close session">
                        <FaLock/>
                    </button>
                </td>
            </tr>)
    } else {
        return (
            <tr key={session.id}>
                <td>
                    <input type="text"
                           name="name"
                           onChange={formik.handleChange}
                           value={formik.values.name}/>
                    {formik.errors.name &&
                        <span className="form-error">{formik.errors.name}</span>}
                </td>
                <td>
                    <input type="text"
                           name="location"
                           onChange={formik.handleChange}
                           value={formik.values.location}/>
                    {formik.errors.location &&
                        <span className="form-error">{formik.errors.location}</span>}
                </td>
                <td>
                    <input type="text"
                           name="date"
                           onChange={formik.handleChange}
                           value={formik.values.date}/>
                    {formik.errors.date &&
                        <span className="form-error">{formik.errors.date}</span>}
                </td>
                <td>{session.ended ? "Done" : "Ongoing"}</td>
                <td className="td-action">
                    <button id="session-save" disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                            type="submit" onClick={formik.submitForm}
                            className="btn-main btn-left">Save
                    </button>
                    <button id="session-cancel" disabled={formik.isSubmitting} type="button"
                            className="btn-main btn-right"
                            onClick={cancel}>Cancel
                    </button>
                    {formik.status && formik.status.error && <p className='form-error'>{formik.status.error}</p>}
                </td>
            </tr>
        )
    }
}

export default SessionOverview;