import {useFormik} from "formik";
import {API, graphqlOperation} from "aws-amplify";
import {createTag} from "../../graphql/mutations";
import React from "react";
import {useNavigate} from "react-router-dom";

const CreateTag = ({onCreate, order}) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validate: ({name, description}) => {
            const errors = {};

            if (!name) {
                errors.name = 'Required';
            }
            if (!description) {
                errors.description = 'Required';
            }

            return errors;
        },
        onSubmit: async ({name, description}) => {
            API.graphql(graphqlOperation(createTag, {
                input: {
                    name,
                    description,
                    order,
                    removed: false
                }
            })).then(() => {
                onCreate();
                navigate(-1);
            }).catch(error => formik.setStatus({error: error.errors ? error.errors.map(e => e.message) : error.message}));
        }
    });

    return (
        <div className="popup">
            <form className="popup-inner" onSubmit={formik.handleSubmit}>
                <div>
                    {formik.status && formik.status.error && <p className='form-error'>{formik.status.error}</p>}
                    <div>
                        <label htmlFor="name">Name</label>
                        <input id="name"
                               name="name"
                               type="text"
                               placeholder="Name"
                               onChange={formik.handleChange}
                               value={formik.values.name}
                        />
                        {formik.errors.name && <span className="form-error">{formik.errors.name}</span>}
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <input id="description"
                               name="description"
                               type="text"
                               placeholder="Description"
                               onChange={formik.handleChange}
                               value={formik.values.description}
                        />
                        {formik.errors.description && <span className="form-error">{formik.errors.description}</span>}
                    </div>
                    <div>
                        <button disabled={formik.isSubmitting}
                                type="button"
                                className="btn-main btn-right"
                                onClick={() => navigate(-1)}>
                            Cancel
                        </button>
                        <button disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                                type="submit"
                                className="btn-main btn-left">
                            Add new tag
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CreateTag;