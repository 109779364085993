import {NavLink, useNavigate} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import {FaSignOutAlt} from "react-icons/fa";
import {useAuthenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

const Header = () => {
    const {authStatus, signOut} = useAuthenticator(context => [context.authStatus])
    const navigate = useNavigate();

    async function doSignOut() {
        await signOut();
        navigate('/');
    }

    return (
        <header className="admin-header clearfix">
            <h1>Create your resume</h1>
            {(authStatus === 'authenticated') &&
                <nav className="admin-nav">
                    <ul>
                        <NavLink to="/admin/resumes" className="nav-selected">
                            <li key="resumes">Overview resume</li>
                        </NavLink>
                        <NavLink to="/admin/tiles" className="nav-selected">
                            <li key="tiles">Manage tiles</li>
                        </NavLink>
                        <NavLink to="/admin/tags" className="nav-selected">
                            <li key="tags">Manage tags</li>
                        </NavLink>
                        <NavLink to="/admin/sessions" className="nav-selected">
                            <li key="sessions">Manage sessions</li>
                        </NavLink>
                        <NavLink to="/admin/changepassword" className="nav-selected">
                            <button key="changepassword" className="btn-icon btn-signout">Change password</button>
                        </NavLink>
                        <button className="btn-icon btn-signout" onClick={doSignOut}>
                            <FaSignOutAlt/>
                            Sign out
                        </button>
                    </ul>
                </nav>}
        </header>
    );
}

Header.propTypes = {
    showAdminLink: PropTypes.bool,
    backLink: PropTypes.string
};

export default Header;

