import {useEffect, useState} from "react";
import {Storage} from "aws-amplify";

const S3Image = ({image, alt, onClick}) => {
    const [imageURI, setImageURI] = useState();

    useEffect(() => {
        if (image) {
            Storage.get(image.key, {level: image.level, identityId: image.identityId})
                .then(setImageURI)
        }
    }, [image]);

    return <img alt={alt} src={imageURI} onClick={onClick}/>;
}

export default S3Image;