import {API, graphqlOperation, Storage} from "aws-amplify";
import {FaEdit, FaTrash} from "react-icons/fa";
import React from "react";
import {deleteTile} from "../../graphql/mutations";
import S3Image from "../../util/S3Image";
import {useNavigate} from "react-router-dom";

export const deleteTagTile = /* GraphQL */ `
  mutation DeleteTagTile(
    $input: DeleteTagTileInput!
    $condition: ModelTagTileConditionInput
  ) {
    deleteTagTile(input: $input, condition: $condition) {
      id
    }
  }
`;

const Tile = ({tile, onRemove, startEdit}) => {
    const navigate = useNavigate();

    function edit() {
        startEdit(tile);
        navigate('/admin/tiles/edit')
    }

    function remove() {
        Promise.all([
            Storage.remove(tile.icon.key, {level: tile.icon.level, identityId: tile.icon.identityId}),
            API.graphql(graphqlOperation(deleteTile,
                {input: {id: tile.id}})),
            ...tile.tags.items.map(tt => API.graphql(
                graphqlOperation(deleteTagTile, {
                    input: {
                        id: tt.id
                    }
                })
            ))]
        ).then(() => onRemove(tile))
            .catch(console.error);
    }

    return (
        <li className="tile-container" key={tile.id}>
            <div className="img-container tile-icon">
                <S3Image image={tile.icon} alt={tile.name}/>
            </div>
            <div>{tile.name}</div>
            <div className="td-action">
                <button id="tile-edit" type="button" className="btn-icon table-icon"
                        onClick={edit}>
                    <FaEdit/>
                </button>
                <button id="tile-delete" type="button" className="btn-icon table-icon"
                        onClick={remove}>
                    <FaTrash/>
                </button>
            </div>
        </li>
    );
}

export default Tile;