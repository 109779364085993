import React, {useEffect, useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import {Route, Routes, useNavigate} from "react-router-dom";
import CreateTile from "./CreateTile";
import EditTile from "./EditTile";
import Tile from "./Tile";

const listTiles = /* GraphQL */ `
  query ListTiles(
    $filter: ModelTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        removed
        createdAt
        updatedAt
        owner
        icon {
          key
          level
          identityId
        }
        tags {
          items {
            id
            tagID
          }
        }
      }
      nextToken
    }
  }
`;

const TileOverview = () => {
    const [tiles, setTiles] = useState([]);
    const [editing, setEditing] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        fetchTiles();
    }, []);

    function fetchTiles() {
        API.graphql(graphqlOperation(listTiles, {}))
            .then(result => setTiles(result.data.listTiles.items))
            .catch(console.error)
    }

    function deleteTile(tile) {
        setTiles(tiles => {
            return [...tiles.filter(t => t.id !== tile.id)];
        });
    }

    const sorted = tiles.sort((a, b) => a.order - b.order)

    return (<main>
        <h2>Manage tiles</h2>
        <button className="btn-main btn-addTile clearfix" onClick={() => {
            navigate('/admin/tiles/create')
        }}> Add tile
        </button>
        <Routes>
            <Route path="create" element={<CreateTile onCreate={fetchTiles}/>}/>
            <Route path="edit" element={<EditTile tile={editing} onUpdate={fetchTiles}/>}/>
        </Routes>
        <div className="clearfix">
            <ul className="tile-overview">
                {sorted
                    .map((tile) =>
                        <Tile key={tile.id}
                              tile={tile}
                              startEdit={(tile) => setEditing(tile)}
                              onRemove={deleteTile}
                        />)}
            </ul>
        </div>
    </main>);
}


export default TileOverview;