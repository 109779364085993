import {Outlet} from "react-router-dom";
import Header from "./Header";
import {Authenticator} from "@aws-amplify/ui-react";
import React from "react";

const PrivateRootPage = () => {
    return (<Authenticator.Provider>
        <Header/>
        <Authenticator variation="modal">
            <Outlet/>
        </Authenticator>
    </Authenticator.Provider>);
}

export default PrivateRootPage;
