import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from "aws-amplify";
import listTags from "./ListTags";
import {useFormik} from "formik";
import validator from "validator";
import S3Image from "../../util/S3Image";
import {useNavigate} from "react-router-dom";
import {createResume} from "../../graphql/mutations";
import {v4 as uuid} from "uuid";

export const createResumeTagTile = /* GraphQL */ `
  mutation CreateResumeTagTile(
    $input: CreateResumeTagTileInput!
    $condition: ModelResumeTagTileConditionInput
  ) {
    createResumeTagTile(input: $input, condition: $condition) {
      id
    }
  }
`;
const Review = ({personal, resume, skills, sessionId, owner}) => {
    const [tags, setTags] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentCredentials().then(credentials => API.graphql({
            query: listTags,
            variables: {},
            authMode: credentials.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        }))
            .then(result => setTags(result.data.listTags.items))
            .catch(console.error);
    }, []);

    const formik = useFormik({
        initialValues: {
            personal, resume, skills
        }, validateOnMount: true,
        validate: ({personal, resume}) => {
            const errors = {};
            if (!personal.photo) {
                errors.image = 'Required';
            }
            if (!personal.email) {
                errors.email = 'Required';
            } else if (!validator.isEmail(personal.email)) {
                errors.email = 'Invalid email address';
            }
            if (!personal.last_name) {
                errors.last_name = 'Required';
            }
            if (!personal.first_name) {
                errors.first_name = 'Required';
            }
            if (!personal.residence) {
                errors.residence = 'Required';
            }
            if (!resume.academic_year) {
                errors.academic_year = 'Required';
            }
            if (!resume.study) {
                errors.study = 'Required';
            }
            return errors;
        }, onSubmit: async ({personal, resume, skills}) => {
            const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(personal.photo.name);
            const imageId = 'photo/' + [uuid(), extension].filter(x => !!x).join('.');
            const credentials = await Auth.currentCredentials();
            await Storage.put(imageId, personal.photo, {
                level: 'public'
            }).then(async image => {
                image.level = 'public';
                return API.graphql({
                    query: createResume, variables: {
                        input: {
                            first_name: personal.first_name,
                            last_name: personal.last_name,
                            residence: personal.residence,
                            email: personal.email,
                            photo: image,
                            study: resume.study,
                            academic_year: resume.academic_year,
                            internship: resume.internship,
                            studentjob: resume.studentjob,
                            job: resume.job,
                            resumeSessionId: sessionId,
                            owner: owner
                        }
                    }, authMode: credentials.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                });
            }).then(result => Promise.all(Object.keys(skills.tags)
                .map(tagId => {
                    return {
                        tagId, tiles: Object.keys(skills.tags[tagId]).filter(tileId => skills.tags[tagId][tileId])
                    }
                })
                .map(({tagId, tiles}) => tiles.map(tileId => API.graphql({
                        query: createResumeTagTile, variables: {
                            input: {
                                resumeID: result.data.createResume.id,
                                resumeTagTileTagId: tagId,
                                resumeTagTileTileId: tileId
                            }
                        },
                        authMode: credentials.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                    }))
                ).reduce((acc, curr) => [...acc, ...curr], [])))
                .then(() => navigate('/'))
                .catch(console.error);
        }
    });

    const interest = {studentjob: resume.studentjob, job: resume.job, internship: resume.internship};

    return (<main className="review-page builder">
        <form onSubmit={formik.handleSubmit}>
            <section className="general">
                <div className="photo">
                    {formik.errors.photo ? <span
                        className="field-error">{formik.errors.photo}</span> : formik.values.personal?.photo &&
                        <img className="review-profile-image"
                             src={URL.createObjectURL(formik.values.personal.photo)}
                             alt="Profile"/>}
                </div>
                <div className="personal">
                    <div className="field-review">
                        <span className="field-name">First name</span>
                        <span className="field-value">{formik.values.personal?.first_name}</span>
                        {formik.errors.first_name && <span className="field-error">{formik.errors.first_name}</span>}
                    </div>
                    <div className="field-review">
                        <span className="field-name">Last name</span>
                        <span className="field-value">{formik.values.personal?.last_name}</span>
                        {formik.errors.last_name && <span className="field-error">{formik.errors.last_name}</span>}
                    </div>
                    <div className="field-review">
                        <span className="field-name">Email</span>
                        <span className="field-value">{formik.values.personal?.email}</span>
                        {formik.errors.email && <span className="field-error">{formik.errors.email}</span>}
                    </div>
                    <div className="field-review">
                        <span className="field-name">Residence</span>
                        <span className="field-value">{formik.values.personal?.residence}</span>
                        {formik.errors.residence && <span className="field-error">{formik.errors.residence}</span>}
                    </div>
                    <div className="field-review">
                        <span className="field-name">Study</span>
                        <span className="field-value">{formik.values.resume?.study}</span>
                        {formik.errors.study && <span className="field-error">{formik.errors.study}</span>}
                    </div>
                    <div className="field-review">
                        <span className="field-name">Academic year</span>
                        <span className="field-value">{formik.values.resume?.academic_year}</span>
                        {formik.errors.academic_year &&
                            <span className="field-error">{formik.errors.academic_year}</span>}
                    </div>
                    <div className="field-review">
                        <span className="field-name">Interested in</span>
                        <span
                            className="field-value">{Object.keys(interest).filter(key => interest[key]).map(key => {
                            return {studentjob: "Student job", job: "Job", internship: "Internship"}[key];
                        }).join(', ') || '/'}</span>
                    </div>
                </div>
            </section>
            <section className="skills">
                <h4>Skills</h4>
                <div className="skill-container">
                    {skills.tags && Object.keys(skills.tags).map(id => tags.find(tag => tag.id === id))
                        .sort((a, b) => a.order - b.order)
                        .filter(tag => tag)
                        .map(tag => skills.tags[tag.id] && Object.keys(skills.tags[tag.id])
                                .filter(id => skills.tags[tag.id][id]).length > 0 &&
                            <div key={tag.id} className="tag-container">
                                <h5>{tag.name}</h5>
                                <div className="review-tile-container">
                                    {Object.keys(skills.tags[tag.id])
                                        .map(id => tag.tiles.items.map(tt => tt.tile).find(tile => tile.id === id))
                                        .filter(tile => tile)
                                        .map(tile => <div key={tile.id} className="review-tile-item">
                                            <div className="img-container">
                                                <S3Image image={tile.icon}/>
                                            </div>
                                            <h4>{tile.name}</h4>
                                        </div>)}
                                </div>
                            </div>)}
                </div>
            </section>
            <button disabled={formik.isSubmitting || !formik.isValid} className="btn-main"
                    type="submit">Submit
            </button>
            <button type="button" disabled={formik.isSubmitting} className="btn-main btn-alt"
                    onClick={() => navigate('/')}>Cancel
            </button>
        </form>
    </main>);
}

export default Review;