import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {compareString} from "../../Utility";
import {API, graphqlOperation} from "aws-amplify";
import {listSessions} from "../../graphql/queries";
import S3Image from "../../util/S3Image";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";
import ResumeDetail from "./ResumeDetail";

export const listResumes = /* GraphQL */ `
  query ListResumes(
    $filter: ModelResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        residence
        email
        study
        academic_year
        internship
        studentjob
        job
        owner
        createdAt
        updatedAt
        photo {
          key
        }
        session {
          id
          name
        }
      }
      nextToken
    }
  }
`;
const ResumeOverview = () => {
    const [sessions, setSessions] = useState([]);
    const [resumes, setResumes] = useState([]);
    const [open, setOpen] = useState();
    const navigate = useNavigate();
    const {user} = useAuthenticator(context => [context.user]);

    useEffect(() => {
        API.graphql(graphqlOperation(listSessions))
            .then(result => setSessions(result.data.listSessions.items))
        API.graphql(graphqlOperation(listResumes, {owner: user.username}))
            .then(result => setResumes(result.data.listResumes.items));
    }, [user]);

    function fetchResumes() {
        API.graphql(graphqlOperation(listResumes, {owner: user.username}))
            .then(result => setResumes(result.data.listResumes.items));
    }

    const formik = useFormik({
        initialValues: {
            sessionId: undefined,
            internship: false,
            studentjob: false,
            job: false
        }, onSubmit: ({sessionId, internship, studentjob, job}) => {
            fetchResumes(sessionId, internship, studentjob, job);
        }
    });

    function openDetail(resume) {
        setOpen(resume);
        navigate('/admin/resumes/detail')
    }

    return <main>
        <h2>Resume overview</h2>
        <Routes>
            <Route path='detail' element={<ResumeDetail resume={open}/>}/>
        </Routes>
        <form className="filter-form" onChange={formik.handleSubmit}>
            <select className="custom-select-single"
                    name="sessionID"
                    value={formik.values.sessionId}
                    onChange={formik.handleChange}>
                <option value={undefined}/>
                {
                    sessions.sort((a, b) => compareString(a.name, b.name))
                        .map(session =>
                            <option key={session.id}
                                    value={session.id}>
                                {session.name}
                            </option>
                        )
                }
            </select>
            <div>
                <input id="internship"
                       name="internship"
                       type="checkbox"
                       onChange={formik.handleChange}
                       checked={formik.values.internship}/>
                <label htmlFor="internship">Internship</label>
            </div>
            <div>
                <input id="studentjob"
                       name="studentjob"
                       type="checkbox"
                       onChange={formik.handleChange}
                       checked={formik.values.studentjob}/>
                <label htmlFor="studentjob">Student job</label>
            </div>
            <div>
                <input id="job"
                       name="job"
                       type="checkbox"
                       onChange={formik.handleChange}
                       checked={formik.values.job}/>
                <label htmlFor="job">Job</label>
            </div>
        </form>
        <div className="clearfix admin-resoverview">
            <ul className="resume-container">
                {resumes.sort((a, b) => compareString(a.email, b.email)).map(resume =>
                    <li className="res-container"
                        key={resume.id}>
                        <div className="res-persinfo-container">
                            <div className="img-container">
                                <S3Image image={resume.photo}/>
                            </div>
                            <h3 className="persinfo-name">{resume.first_name} {resume.last_name}</h3>
                            <h3 className="persinfo-mail">{resume.email}</h3>
                            <h3 className="persinfo-residence">{resume.residence}</h3>
                            <h3 className="persinfo-study">{resume.study}</h3>
                            <h3 className="persinfo-session">{resume.session.name}</h3>
                            <button type="button" className="btn-main btn-signin"
                                    onClick={() => openDetail(resume)}>Full resume
                            </button>
                        </div>
                    </li>)}
            </ul>
        </div>
    </main>
}

export default ResumeOverview;