import {useFormik} from "formik";
import {API, graphqlOperation} from "aws-amplify";
import {createSession} from "../../graphql/mutations";
import React from "react";
import {useNavigate} from "react-router-dom";

const CreateSession = ({onCreate}) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            location: '',
            date: ''
        },
        validate: ({name, location, date}) => {
            const errors = {};

            if (!name) {
                errors.name = 'Required';
            }
            if (!location) {
                errors.location = 'Required';
            }
            if (!date) {
                errors.date = 'Required'
            }

            return errors;
        },
        onSubmit: async ({name, location, date}) => {
            API.graphql(graphqlOperation(createSession, {
                input: {
                    name,
                    location,
                    date,
                    ended: false
                }
            })).then(() => {
                onCreate();
                navigate(-1);
            })
                .catch(error => formik.setStatus({error: error.errors.map(e => e.message)}));
        }
    });

    return (
        <div className="popup">
            <form className="popup-inner" onSubmit={formik.handleSubmit}>
                <div>
                    {formik.status && formik.status.error && <p className='form-error'>{formik.status.error}</p>}
                    <div>
                        <label htmlFor="name">Name</label>
                        <input id="name"
                               name="name"
                               type="text"
                               placeholder="Name"
                               onChange={formik.handleChange}
                               value={formik.values.name}
                        />
                        {formik.errors.name && <span className="form-error">{formik.errors.name}</span>}
                    </div>
                    <div>
                        <label htmlFor="location">Location</label>
                        <input id="location"
                               name="location"
                               type="text"
                               placeholder="Location"
                               onChange={formik.handleChange}
                               value={formik.values.location}
                        />
                        {formik.errors.location && <span className="form-error">{formik.errors.location}</span>}
                    </div>
                    <div>
                        <label htmlFor="date">Date</label>
                        <input id="date"
                               name="date"
                               type="text"
                               placeholder="Date"
                               onChange={formik.handleChange}
                               value={formik.values.date}
                        />
                        {formik.errors.date && <span className="form-error">{formik.errors.date}</span>}
                    </div>
                    <div>
                        <button disabled={formik.isSubmitting}
                                type="button"
                                className="btn-main btn-right"
                                onClick={() => navigate(-1)}>
                            Cancel
                        </button>
                        <button disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                                type="submit"
                                className="btn-main btn-left">
                            Add new session
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CreateSession;