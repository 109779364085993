import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import React from "react";

const Resume = ({initial, onSubmit}) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            academic_year: initial.academic_year || '',
            study: initial.study || '',
            internship: initial.internship || false,
            job: initial.job || false,
            studentjob: initial.studentjob || false
        }, validate: ({academic_year, study}) => {
            const errors = {};
            if (!academic_year) {
                errors.academic_year = 'Required';
            }
            if (!study) {
                errors.study = 'Required';
            }
            return errors;
        }, onSubmit: ({academic_year, study, internship, job, studentjob}) => {
            onSubmit({academic_year, study, internship, job, studentjob});
            navigate("/resume/skills");
        }
    });

    return <main className="builder">
        <form onSubmit={formik.handleSubmit}>
            <fieldset>
                <div className="form-column">
                    <div className="form-column-2">
                        <label htmlFor="academic_year">Academic degree/year:</label>
                        <select tabIndex="2"
                                className="custom-select"
                                name="academic_year"
                                value={formik.values.academic_year}
                                onChange={formik.handleChange}>
                            <option>Select your academic year</option>
                            <option value="FirstBachelor">1st Bachelor</option>
                            <option value="SecondBachelor">2nd Bachelor</option>
                            <option value="ThirdBachelor">3rd Bachelor</option>
                            <option value="FirstMaster">1st Master</option>
                            <option value="SecondMaster">2nd Master</option>
                            <option value="BaNaBa">BaNaBa</option>
                            <option value="Graduaat">Graduaat</option>
                        </select>
                        {formik.errors.academic_year && <p className="form-error">{formik.errors.academic_year}</p>}
                    </div>
                    <div className="form-column-1">
                        <label htmlFor="study">Study</label>
                        <input name="study"
                               tabIndex="1"
                               type="text"
                               placeholder="What do you study?"
                               value={formik.values.study}
                               onChange={formik.handleChange}/>
                        {formik.errors.study && <p className="form-error">{formik.errors.study}</p>}
                    </div>
                </div>
                <div className="check-container">
                    <p>What are you interested in?</p>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                               tabIndex="3"
                               className="custom-control-input"
                               id="customCheck1"
                               name="internship"
                               checked={formik.values.internship}
                               onChange={formik.handleChange}/>
                        <label className="custom-control-label"
                               htmlFor="customCheck1">Internship</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                               tabIndex="3"
                               className="custom-control-input"
                               id="customCheck2"
                               name="studentjob"
                               checked={formik.values.studentjob}
                               onChange={formik.handleChange}/>
                        <label className="custom-control-label"
                               htmlFor="customCheck2">Studentjob</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                               tabIndex="3"
                               className="custom-control-input"
                               id="customCheck3"
                               name="job"
                               checked={formik.values.job}
                               onChange={formik.handleChange}/>
                        <label className="custom-control-label"
                               htmlFor="customCheck3">Job</label>
                    </div>
                </div>
                <div>
                    <button disabled={formik.isSubmitting || !formik.isValid}
                            type="submit"
                            className="btn-main">Next
                    </button>
                    <button type="button"
                            className="btn-main btn-alt"
                            onClick={() => navigate("/")}
                            disabled={formik.isSubmitting}>Cancel
                    </button>
                </div>
            </fieldset>
        </form>
    </main>;
}

export default Resume;