const ListTags = /* GraphQL */`
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        order
        removed
        createdAt
        updatedAt
        owner
        tiles {
          items {
            tile {
              id
              name
              icon {
                key
                level
                identityId
              }
            }
          }
        }
      }
    }
  }
`;

export default ListTags;