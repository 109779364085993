import React, {useState} from "react";
import {useFormik} from "formik";
import {Link} from "react-router-dom";
import {Auth} from "aws-amplify";
import {useAuthenticator} from "@aws-amplify/ui-react";

const ChangePassword = () => {
    const {user: authUser} = useAuthenticator(context => [context.user]);
    const [result, setResult] = useState();

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: ''
        },
        validate: ({oldPassword, newPassword}) => {
            const errors = {};
            if (!oldPassword) {
                errors.oldPassword = 'Required';
            }
            if (!newPassword) {
                errors.newPassword = 'Required';
            }
            return errors;
        },
        onSubmit: async ({oldPassword, newPassword}) => {
            try {
                const result = await Auth.changePassword(authUser, oldPassword, newPassword)
                setResult(result);
            } catch (error) {
                console.log(error);
                setResult('ERROR');
            }
        }
    })

    return (
        <main>
            {result === 'SUCCESS' && <p className='success-block'>Password successfully changed.</p>}
            {result === 'ERROR' &&
                <p className='error-block'>An error occurred while changing your password. Please try again.</p>}
            <form className="auth-form" onSubmit={formik.handleSubmit}>
                <h2>Change password</h2>
                {formik.status && formik.status.error && <p style={{color: 'red'}}>{formik.status.error}</p>}
                <div>
                    <label htmlFor="oldPassword">Old password</label>
                    <input
                        id="oldPassword"
                        type="password"
                        placeholder="Old password"
                        onChange={formik.handleChange}
                        value={formik.values.oldPassword}
                        className={formik.errors.oldPassword ? 'field-error' : null}
                    />
                    {formik.errors.oldPassword && <span className="form-error">{formik.errors.oldPassword}</span>}
                </div>
                <div>
                    <label htmlFor="newPassword">New password</label>
                    <input id="newPassword"
                           type="password"
                           placeholder="New password"
                           onChange={formik.handleChange}
                           value={formik.values.newPassword}
                           className={formik.errors.newPassword ? 'field-error' : null}
                           aria-describedby="passwordHelpInline"
                    />
                    {formik.errors?.newPassword && <span className="form-error">{formik.errors.newPassword}</span>}
                </div>
                <button type="submit" className="btn-main btn-signin">Change password</button>
                <Link to='/admin'>
                    <button type="button" className="btn-main btn-signin">Cancel</button>
                </Link>
            </form>
        </main>
    );
}
export default ChangePassword;