import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {FaPlusCircle, FaTimesCircle} from "react-icons/fa";
import S3Image from "../../util/S3Image";
import {compareString} from "../../Utility";
import SelectTiles from "./SelectTiles";
import listTags from "./ListTags";

const Skills = ({initial, onSubmit, owner}) => {
    const [availableTags, setAvailableTags] = useState([]);
    const [showPopup, setShowPopup] = useState(undefined);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            tags: initial.tags || {}
        }, onSubmit: ({tags}) => {
            onSubmit({tags});
            navigate("/resume/review");
        },
        enableReinitialize: true
    });

    useEffect(() => {
        Auth.currentCredentials().then(credentials => API.graphql({
            query: listTags,
            variables: {filter: {owner: {eq: owner}}},
            authMode: credentials.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        }))
            .then(result => {
                setAvailableTags(result.data.listTags.items);
            });
    }, [owner]);

    async function toggle(tagId, tileId) {
        const tag = formik.values.tags[tagId] || {};
        formik.setFieldValue('tags', {
            ...formik.values.tags,
            [tagId]: {
                ...(tag),
                [tileId]: !tag[tileId]
            }
        });
    }

    return <main className="builder">
        <div className="main">
            <form onSubmit={formik.handleSubmit}>
                <div className="selectedTagTiles">
                    {availableTags.sort((a, b) => a.order - b.order).map((tag) =>
                        <div key={tag.id}>
                            {showPopup === tag.id &&
                                <SelectTiles tiles={tag.tiles.items.map(tt => tt.tile)}
                                             selectedTiles={formik.values.tags[tag.id] || {}}
                                             toggle={id => toggle(tag.id, id)}
                                             close={() => setShowPopup(false)}/>
                            }
                            <h4 className="h-allrestags">{tag.name}</h4>
                            <button type="button"
                                    className="btn-add-skill"
                                    onClick={() => setShowPopup(tag.id)}>
                                <FaPlusCircle/>
                            </button>
                            <div className="tiles-selected-container">
                                {formik.values.tags[tag.id] && Object.keys(formik.values.tags[tag.id])
                                    .filter((key) => formik.values.tags[tag.id][key])
                                    .map(id => tag.tiles.items
                                        .map(tt => tt.tile)
                                        .find(tile => tile.id === id))
                                    .sort((a, b) => compareString(a.name, b.name))
                                    .map(tile =>
                                        <div key={tile.id} className="tiles-selected-item">
                                            <button type="button"
                                                    title="Remove skill"
                                                    className="btn-close"
                                                    onClick={() => toggle(tag.id, tile.id)}
                                            >
                                                <FaTimesCircle/>
                                            </button>
                                            <div className="img-container">
                                                <S3Image image={tile.icon} alt={tile.name}/>
                                            </div>
                                            <h4>{tile.name}</h4>
                                        </div>)}
                            </div>
                        </div>)}
                </div>
                <button disabled={formik.isSubmitting || !formik.isValid}
                        type="submit"
                        className="btn-main">Review
                </button>
                <button disabled={formik.isSubmitting}
                        type="button"
                        className="btn-main btn-alt"
                        onClick={() => navigate('/')}>Cancel
                </button>
            </form>
        </div>
    </main>;
}

export default Skills;