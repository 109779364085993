import React from "react";
import {useFormik} from "formik";
import ImageInput from "../../util/ImageInput";
import validator from "validator";
import {useNavigate} from "react-router-dom";
import imagePlaceholder from "../../util/imgPlaceholder.png";

const PersonalInfo = ({initial, onSubmit}) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            image: initial.photo,
            email: initial.email || '',
            last_name: initial.last_name || '',
            first_name: initial.first_name || '',
            residence: initial.residence || ''
        }, validate: ({image, email, last_name, first_name, residence}) => {
            const errors = {};
            if (!image) {
                errors.image = 'Required';
            }
            if (!email) {
                errors.email = 'Required';
            } else if (!validator.isEmail(email)) {
                errors.email = 'Invalid email address';
            }
            if (!last_name) {
                errors.last_name = 'Required';
            }
            if (!first_name) {
                errors.first_name = 'Required';
            }
            if (!residence) {
                errors.residence = 'Required';
            }
            return errors;
        }, onSubmit: ({image: photo, email, last_name, first_name, residence}) => {
            onSubmit({photo, email, last_name, first_name, residence});
            navigate("/resume/study");
        }
    });

    return <main className="builder">
        <form onSubmit={formik.handleSubmit}>
            <div className="personal-image">
                <ImageInput id="image"
                            name="image"
                            placeholder={imagePlaceholder}
                            image={formik.values.image}
                            onChange={(file) => formik.setFieldValue('image', file)}/>
                {formik.errors.image ? <p className="form-error">{formik.errors.image}</p> : ""}
            </div>
            <div>
                <label htmlFor="email">Mail</label>
                <input id="email"
                       name="email"
                       type="text"
                       tabIndex="2"
                       value={formik.values.email}
                       onChange={formik.handleChange}
                       placeholder="Mail"/>
                {formik.errors.email ? <p className="form-error">{formik.errors.email}</p> : ""}
            </div>
            <div className="form-column">
                <div className="form-column-2">
                    <label id="lablname" htmlFor="lname">Last Name</label>
                    <input id="lname"
                           name="last_name"
                           type="text"
                           tabIndex="4"
                           className="input-name .form-column-item"
                           value={formik.values.last_name}
                           onChange={formik.handleChange}
                           placeholder="Last name"/>
                    {formik.errors.last_name ? <p className="form-error">{formik.errors.last_name}</p> : ""}
                </div>
                <div className="form-column-1">
                    <label>First Name</label>
                    <input id="fname"
                           name="first_name"
                           type="text"
                           tabIndex="3"
                           className="input-name form-column-item"
                           value={formik.values.first_name}
                           onChange={formik.handleChange}
                           placeholder="First name"/>
                    {formik.errors.first_name ? <p className="form-error">{formik.errors.first_name}</p> : ""}
                </div>
            </div>
            <div>
                <label>City</label>
                <input id="residence"
                       name="residence"
                       type="text"
                       tabIndex="5"
                       value={formik.values.residence}
                       onChange={formik.handleChange}
                       placeholder="Where do you live?"/>
                {formik.errors.residence ? <p className="form-error">{formik.errors.residence}</p> : ""}
            </div>
            <div>
                <button disabled={formik.isSubmitting || !formik.isValid}
                        type="submit"
                        className="btn-main">Next
                </button>
                <button type="button"
                        className="btn-main btn-alt"
                        onClick={() => navigate('/')}>Cancel
                </button>
            </div>
        </form>
    </main>
}

export default PersonalInfo;