const sessionIdLocalStorageKey = 'resume-builder-session-id';

export function getSessionId() {
    return localStorage.getItem(sessionIdLocalStorageKey);
}

export function setSessionId(sessionId) {
    localStorage.setItem(sessionIdLocalStorageKey, sessionId);
}

export function compareString(a, b) {
    return a > b ? 1 : a === b ? 0 : -1
}