import React, {useState} from "react";
import {Route, Routes} from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import Resume from "./Resume";
import Skills from "./Skills";
import Review from "./Review";

const ResumeBuilder = ({sessionId, owner}) => {
    const [personal, setPersonal] = useState({});
    const [resume, setResume] = useState({});
    const [skills, setSkills] = useState({});

    return <Routes>
        <Route path="personal" element={<PersonalInfo initial={personal} onSubmit={setPersonal}/>}/>
        <Route path="study" element={<Resume initial={resume} onSubmit={setResume}/>}/>
        <Route path="skills" element={<Skills initial={skills} onSubmit={setSkills} owner={owner}/>}/>
        <Route path="review" element={<Review sessionId={sessionId} owner={owner} personal={personal} resume={resume}
                                              skills={skills}/>}/>
    </Routes>
}

export default ResumeBuilder;