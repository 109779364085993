import {FaTimes} from "react-icons/fa";
import React from "react";
import {compareString} from "../../Utility";
import S3Image from "../../util/S3Image";


const SelectTiles = ({toggle, close, tiles, selectedTiles}) => {
    return (
        <div>
            <div className="popup clickable" onClick={close}>
                <button className="btn-close" onClick={close}>
                    <FaTimes/>
                </button>
            </div>
            <div className="popup-inner">
                <h2>Select skills</h2>
                <div className="tile-select-container">
                    {tiles.sort((a, b) => compareString(a.name, b.name)).map(tile =>
                        <div className="tile-select-item" key={tile.id}>
                            <div className="img-container btn-like"
                                 onClick={() => toggle(tile.id)}>
                                <S3Image image={tile.icon}/>
                                <span>{tile.name}</span>
                            </div>
                            {Object.keys(selectedTiles).filter(key => selectedTiles[key]).includes(tile.id) ?
                                <button type="button" className="btn-main btn-alt"
                                        onClick={() => toggle(tile.id)}>Remove</button>
                                : <button type="button" className="btn-main"
                                          onClick={() => toggle(tile.id)}>Add</button>}
                        </div>)}
                </div>
            </div>
        </div>
    );
}

export default SelectTiles;