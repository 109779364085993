import {FaTimes} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import S3Image from "../../util/S3Image";
import {useNavigate} from "react-router-dom";
import {API, graphqlOperation} from "aws-amplify";

export const getResume = /* GraphQL */ `
  query GetResume($id: ID!) {
    getResume(id: $id) {
      id
      first_name
      last_name
      residence
      email
      photo {
        key
        level
        identityId
      }
      study
      academic_year
      internship
      studentjob
      job
      owner
      session {
        name
        location
        date
        ended
        id
      }
      rtt {
        items {
          id
          tag {
            id
            name
            description
          }
          tile {
            id
            name
            icon {
              key
              level
              identityId
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      resumeSessionId
    }
  }
`;
const ResumeDetail = ({resume}) => {
    const navigate = useNavigate();
    const [fullResume, setFullResume] = useState();
    useEffect(() => {
        API.graphql(graphqlOperation(
            getResume,
            {
                id: resume.id
            }
        )).then(result => setFullResume(result.data.getResume))
            .catch(console.error)
    }, [resume])

    function renderSkills(rtt) {
        if (!rtt) return '';
        let rtts = [];//container for tile views
        //Group Tiles linked to the Resume by Tag
        let tags = [];
        let rttMap = rtt.items.reduce(
            (acc, cv) => {
                if (!acc.has(cv.tag.id)) {
                    tags.push(cv.tag);
                    acc.set(cv.tag.id, [])
                }
                acc.get(cv.tag.id).push(cv.tile);
                return acc;
            },// accumulator callback: called for each value with the below map as first parameter, the value as the second
            new Map() // initial accumulator value
        );

        tags = tags.sort((a, b) => a.order - b.order);

        for (const tag of tags) {
            rtts.push(
                <div className="res-skill-container" key={tag.id}>
                    <h4>{tag.name}</h4>
                    {rttMap.get(tag.id).map((tile) => (
                            <div className="res-img-container" key={tile.id}>
                                <span className="res-img-description">{tile.name}</span>
                                <S3Image image={tile.icon}/>
                            </div>
                        )
                    )}
                </div>
            )
        }
        return rtts;
    }

    function close() {
        navigate('/admin/resumes');
    }

    const loadedResume = fullResume || resume;

    return (
        <div>
            <div className="popup clickable" onClick={close}>
                <button type="button" className="btn-close" onClick={close}>
                    <FaTimes/>
                </button>
            </div>
            <div className="popup-inner">
                <div className="resume-detail-container">
                    <div className="img-container">
                        <S3Image alt="profile image" image={loadedResume.photo}/>
                    </div>
                    <div className="detail-info">
                        <h3 className="persinfo-name">{loadedResume.first_name} {loadedResume.last_name}</h3>
                    </div>
                    <p className="persinfo-mail">{loadedResume.email}</p>
                    <p className="persinfo-residence">{loadedResume.residence}</p>
                    <p className="persinfo-study">{loadedResume.study}</p>
                    <p className="persinfo-acad">{loadedResume.academic_year}</p>
                    <div className="check-detail">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" readOnly className="custom-control-input" id="internship"
                                   name="internship" checked={loadedResume.internship}/>
                            <label className="custom-control-label" htmlFor="internship">Internship</label>
                            <input type="checkbox" readOnly className="custom-control-input" id="studentjob"
                                   name="studentjob" checked={loadedResume.studentjob}/>
                            <label className="custom-control-label" htmlFor="studentjob">Studentjob</label>
                            <input type="checkbox" readOnly className="custom-control-input" id="job" name="job"
                                   checked={loadedResume.job}/>
                            <label className="custom-control-label" htmlFor="job">Work</label>
                        </div>
                    </div>
                </div>
                <p className="persinfo-session clearfix">{loadedResume.session.name}</p>
                <div className="res-skill-container">{renderSkills(loadedResume.rtt)}</div>
                <button className="btn-main" onClick={() => navigate('/resume/export/' + resume.id)}>Export</button>
            </div>
        </div>
    );
}

export default ResumeDetail;