/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteResume = /* GraphQL */ `
  mutation DeleteResume(
    $input: DeleteResumeInput!
    $condition: ModelResumeConditionInput
  ) {
    deleteResume(input: $input, condition: $condition) {
      id
      first_name
      last_name
      residence
      email
      photo {
        key
        level
        identityId
      }
      study
      academic_year
      internship
      studentjob
      job
      owner
      session {
        name
        location
        date
        ended
        id
        createdAt
        updatedAt
        owner
      }
      rtt {
        nextToken
      }
      createdAt
      updatedAt
      resumeSessionId
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      description
      order
      removed
      owner
      tiles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      description
      order
      removed
      owner
      tiles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      description
      order
      removed
      owner
      tiles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTile = /* GraphQL */ `
  mutation CreateTile(
    $input: CreateTileInput!
    $condition: ModelTileConditionInput
  ) {
    createTile(input: $input, condition: $condition) {
      id
      name
      removed
      icon {
        key
        level
        identityId
      }
      tags {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTile = /* GraphQL */ `
  mutation UpdateTile(
    $input: UpdateTileInput!
    $condition: ModelTileConditionInput
  ) {
    updateTile(input: $input, condition: $condition) {
      id
      name
      removed
      icon {
        key
        level
        identityId
      }
      tags {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTile = /* GraphQL */ `
  mutation DeleteTile(
    $input: DeleteTileInput!
    $condition: ModelTileConditionInput
  ) {
    deleteTile(input: $input, condition: $condition) {
      id
      name
      removed
      icon {
        key
        level
        identityId
      }
      tags {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      name
      location
      date
      ended
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      name
      location
      date
      ended
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      name
      location
      date
      ended
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTagTile = /* GraphQL */ `
  mutation CreateTagTile(
    $input: CreateTagTileInput!
    $condition: ModelTagTileConditionInput
  ) {
    createTagTile(input: $input, condition: $condition) {
      id
      tagID
      tileID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTagTile = /* GraphQL */ `
  mutation UpdateTagTile(
    $input: UpdateTagTileInput!
    $condition: ModelTagTileConditionInput
  ) {
    updateTagTile(input: $input, condition: $condition) {
      id
      tagID
      tileID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTagTile = /* GraphQL */ `
  mutation DeleteTagTile(
    $input: DeleteTagTileInput!
    $condition: ModelTagTileConditionInput
  ) {
    deleteTagTile(input: $input, condition: $condition) {
      id
      tagID
      tileID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createResume = /* GraphQL */ `
  mutation CreateResume(
    $input: CreateResumeInput!
    $condition: ModelResumeConditionInput
  ) {
    createResume(input: $input, condition: $condition) {
      id
      first_name
      last_name
      residence
      email
      photo {
        key
        level
        identityId
      }
      study
      academic_year
      internship
      studentjob
      job
      owner
      session {
        name
        location
        date
        ended
        id
        createdAt
        updatedAt
        owner
      }
      rtt {
        nextToken
      }
      createdAt
      updatedAt
      resumeSessionId
    }
  }
`;
export const updateResume = /* GraphQL */ `
  mutation UpdateResume(
    $input: UpdateResumeInput!
    $condition: ModelResumeConditionInput
  ) {
    updateResume(input: $input, condition: $condition) {
      id
      first_name
      last_name
      residence
      email
      photo {
        key
        level
        identityId
      }
      study
      academic_year
      internship
      studentjob
      job
      owner
      session {
        name
        location
        date
        ended
        id
        createdAt
        updatedAt
        owner
      }
      rtt {
        nextToken
      }
      createdAt
      updatedAt
      resumeSessionId
    }
  }
`;
export const createResumeTagTile = /* GraphQL */ `
  mutation CreateResumeTagTile(
    $input: CreateResumeTagTileInput!
    $condition: ModelResumeTagTileConditionInput
  ) {
    createResumeTagTile(input: $input, condition: $condition) {
      id
      resume {
        id
        first_name
        last_name
        residence
        email
        study
        academic_year
        internship
        studentjob
        job
        owner
        createdAt
        updatedAt
        resumeSessionId
      }
      resumeID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      resumeTagTileTagId
      resumeTagTileTileId
      owner
    }
  }
`;
export const updateResumeTagTile = /* GraphQL */ `
  mutation UpdateResumeTagTile(
    $input: UpdateResumeTagTileInput!
    $condition: ModelResumeTagTileConditionInput
  ) {
    updateResumeTagTile(input: $input, condition: $condition) {
      id
      resume {
        id
        first_name
        last_name
        residence
        email
        study
        academic_year
        internship
        studentjob
        job
        owner
        createdAt
        updatedAt
        resumeSessionId
      }
      resumeID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      resumeTagTileTagId
      resumeTagTileTileId
      owner
    }
  }
`;
export const deleteResumeTagTile = /* GraphQL */ `
  mutation DeleteResumeTagTile(
    $input: DeleteResumeTagTileInput!
    $condition: ModelResumeTagTileConditionInput
  ) {
    deleteResumeTagTile(input: $input, condition: $condition) {
      id
      resume {
        id
        first_name
        last_name
        residence
        email
        study
        academic_year
        internship
        studentjob
        job
        owner
        createdAt
        updatedAt
        resumeSessionId
      }
      resumeID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      resumeTagTileTagId
      resumeTagTileTileId
      owner
    }
  }
`;
