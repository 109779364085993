import {useFormik} from "formik";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import {createTagTile, createTile} from "../../graphql/mutations";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ImageInput from "../../util/ImageInput";
import imagePlaceholder from "../../util/imgPlaceholder.png";
import {v4 as uuid} from "uuid";
import {listTagsMinimal} from "./graphql";
import {compareString} from "../../Utility";

const CreateTile = ({onCreate}) => {
    const navigate = useNavigate();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        API.graphql(graphqlOperation(listTagsMinimal))
            .then(result => setTags(result.data.listTags.items))
            .catch(error => console.log(error));
    }, [])

    const formik = useFormik({
        initialValues: {
            image: undefined, name: '', tags: []
        }, validate: ({image, name, tags}) => {
            const errors = {};

            if (!image) {
                errors.image = 'Required';
            }
            if (!name) {
                errors.name = 'Required';
            }
            if (tags.length === 0) {
                errors.tags = 'Select at least one tag';
            }
            return errors;
        }, onSubmit: async ({image, name, tags}) => {
            try {
                const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(image.name);
                const icon = 'tile/' + [uuid(), extension].filter(x => !!x).join('.');
                const savedIcon = await Storage.put(icon, image, {
                    level: 'protected'
                });
                savedIcon.level = 'protected';
                const identity = await Auth.currentCredentials();
                savedIcon.identityId = identity.identityId;
                const result = await API.graphql(graphqlOperation(createTile, {
                    input: {
                        icon: savedIcon, name, removed: false
                    }
                }));

                const tileId = result.data.createTile.id;
                await Promise.all(tags.map(tagId => {
                    return API.graphql(graphqlOperation(createTagTile, {input: {tagID: tagId, tileID: tileId}}))
                }));
                onCreate();
                navigate(-1);
            } catch (error) {
                formik.setStatus({error: error.errors ? error.errors.map(e => e.message) : error.message})
            }
        }
    });

    return (<div className="popup">
        <form className="popup-inner" onSubmit={formik.handleSubmit}>
            <div>
                {formik.status && formik.status.error && <p className='form-error'>{formik.status.error}</p>}
                <div className="tile-image">
                    <label htmlFor="image">Image</label>
                    <ImageInput id="image"
                                name="image"
                                placeholder={imagePlaceholder}
                                image={formik.values.image}
                                onChange={(file) => formik.setFieldValue('image', file)}/>
                    {formik.errors.image && <span className="form-error">{formik.errors.image}</span>}
                </div>
                <div>
                    <label htmlFor="name">Name</label>
                    <input id="name"
                           name="name"
                           type="text"
                           placeholder="Name"
                           onChange={formik.handleChange}
                           value={formik.values.name}
                    />
                    {formik.errors.name && <span className="form-error">{formik.errors.name}</span>}
                </div>
                <div>
                    <label htmlFor="tags">Tags</label>
                    <select id="tags" name="tags" value={formik.values.tags} onChange={formik.handleChange}
                            className="custom-select" multiple>
                        {tags.sort((a, b) => compareString(a.name, b.name))
                            .map(tag => <option key={tag.id} value={tag.id}>{tag.name}</option>)}
                    </select>
                    {formik.errors.tags && <span className="form-error">{formik.errors.tags}</span>}
                </div>
                <div>
                    <button disabled={formik.isSubmitting}
                            type="button"
                            className="btn-main btn-right"
                            onClick={() => navigate(-1)}>
                        Cancel
                    </button>
                    <button disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                            type="submit"
                            className="btn-main btn-left">
                        Add new tile
                    </button>
                </div>
            </div>
        </form>
    </div>);
}

export default CreateTile;