import React from "react";
import YouTube from "react-youtube";
import {Link} from "react-router-dom";

const Start = ({session}) => {


    return (<main>
        {!session && <h4>No session found</h4>}
        {session && <h4>{session.name} ({session.location}):</h4>}
        <YouTube className="img-container"
                 videoId="JyxOcPUjcbw"/>
        <div>
            <Link to="/resume/personal">
                <button disabled={!session} className="btn-main">Create Resume</button>
            </Link>
        </div>
    </main>)
};

export default Start;