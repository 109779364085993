import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {FaChevronLeft, FaCog} from "react-icons/fa";

const Header = ({showBackLink, showAdminLink}) => {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1);
    }


    return (
        <header className="student_header">
            {showBackLink && (
                <button onClick={goBack} className="btn-icon btn-back">
                    <FaChevronLeft/>
                </button>
            )}
            <h1>
                Create your resume
            </h1>
            {showAdminLink && (
                <Link to="/admin/resumes">
                    <FaCog/>
                </Link>
            )}
        </header>
    );
}

export default Header;

