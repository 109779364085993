/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getResume = /* GraphQL */ `
  query GetResume($id: ID!) {
    getResume(id: $id) {
      id
      first_name
      last_name
      residence
      email
      photo {
        key
        level
        identityId
      }
      study
      academic_year
      internship
      studentjob
      job
      owner
      session {
        name
        location
        date
        ended
        id
        createdAt
        updatedAt
        owner
      }
      rtt {
        nextToken
      }
      createdAt
      updatedAt
      resumeSessionId
    }
  }
`;
export const listResumes = /* GraphQL */ `
  query ListResumes(
    $filter: ModelResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        residence
        email
        study
        academic_year
        internship
        studentjob
        job
        owner
        createdAt
        updatedAt
        resumeSessionId
      }
      nextToken
    }
  }
`;
export const getResumeTagTile = /* GraphQL */ `
  query GetResumeTagTile($id: ID!) {
    getResumeTagTile(id: $id) {
      id
      resume {
        id
        first_name
        last_name
        residence
        email
        study
        academic_year
        internship
        studentjob
        job
        owner
        createdAt
        updatedAt
        resumeSessionId
      }
      resumeID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      resumeTagTileTagId
      resumeTagTileTileId
      owner
    }
  }
`;
export const listResumeTagTiles = /* GraphQL */ `
  query ListResumeTagTiles(
    $filter: ModelResumeTagTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumeTagTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resumeID
        createdAt
        updatedAt
        resumeTagTileTagId
        resumeTagTileTileId
        owner
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      description
      order
      removed
      owner
      tiles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTile = /* GraphQL */ `
  query GetTile($id: ID!) {
    getTile(id: $id) {
      id
      name
      removed
      icon {
        key
        level
        identityId
      }
      tags {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTiles = /* GraphQL */ `
  query ListTiles(
    $filter: ModelTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      name
      location
      date
      ended
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        location
        date
        ended
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTagTile = /* GraphQL */ `
  query GetTagTile($id: ID!) {
    getTagTile(id: $id) {
      id
      tagID
      tileID
      tag {
        id
        name
        description
        order
        removed
        owner
        createdAt
        updatedAt
      }
      tile {
        id
        name
        removed
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTagTiles = /* GraphQL */ `
  query ListTagTiles(
    $filter: ModelTagTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tagID
        tileID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
